import ReactCaroussel from "react-caroussel";
import "react-caroussel/dist/index.css";

import { 
    Box, 
    Button, 
    styled, 
    Typography 
} from "@mui/material";
import { useTheme } from '@mui/material/styles';

/* LLAMADAS DE SERVICIOS API */
import SiteService from '../services/SiteService';
import { useEffect, useState } from "react";

const Slide = ({slide}) => {
    const theme = useTheme();

    const CustomSlideBox = styled(Box) (({ theme }) => ({
        minHeight: '80vh',
        display: 'flex',
    }));

    const CustomBox = styled(Box) (({ theme }) => ({
        height: '50%',
        width: {
            xs: '60%',
            md: '40%'
    },
        backgroundColor: 'white',
        opacity: 0.65,
        padding: theme.spacing(3),
        margin: theme.spacing(3),
        borderRadius: 10,
        display: 'flex',
//        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            alignItems: 'center',
            textAlign: 'center',
//        }
    }));

    const BoxText = styled(Box) (({ theme }) => ({
        flex: '1',
        [theme.breakpoints.down('md')]: {
            flex: '2',
            textAlign: 'center',
            display: 'block'
        },
    }));

    const handleOnClickButton = async () => {
        try {
            await SiteService.OPERATIONLOG_Register({
                type: "slider",
                action: "access",
                associated_id: slide.id
            });
        } catch (err) {
            const errorMessage = "Error: " + err.message;
            console.log(errorMessage);
        }
    };


    return (
        <CustomSlideBox
        // Imagen de Fondo
        sx={{
            backgroundImage: {
                xs: `url(${slide.image_portrait})`,   
                md: `url(${slide.image_landscape})`
            },
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            alignItems: {
                xs: "flex-end",
                md: `${ slide.align_items }`
            },            
            justifyContent: {
                xs: "center",
                md: `${ slide.justify_content }`
            },            
        }}
        >
            <CustomBox >
                {/*  Box text  */}
                <BoxText 
                >
                    <Typography
                        variant='h2'
                        component= 'h1'
                        sx={{
                            fontWeight: 700,
                            color: '#6d429b',
                        }}
                    >
                        { slide.title }
                    </Typography>

                    <Typography
                    variant='h5'
                    component='h4'
                    sx={{
                        py: 1,
                        lineHeight: 1.6,
                        color: '#6d429b',
                    }}
                    >
                        { slide.description }
                    </Typography>
                    <a 
                        href={ slide.link }
                        rel='noreferrer'
                        target='_blank'
                    >                
                        <Button variant="contained" 
                            sx={{ mt: theme.spacing(3), mb: theme.spacing(3) }}
                            onClick={ handleOnClickButton }
                        >
                            { slide.button_text}
                        </Button>
                    </a>
                </BoxText>
            </CustomBox>
        </CustomSlideBox>
    );
  };

export default function Slider() {
    const [slides, setSlides] = useState([]);

    const ADVERTINSINGSLIDER_CompleteList = async () => {
        try {
        const response = await SiteService.ADVERTINSINGSLIDER_CompleteList();
        setSlides(response.datos.data);
        } catch (err) {
        const errorMessage = "Error: " + err.message;
        console.log(errorMessage);
        }
    }

    useEffect(() => {
        ADVERTINSINGSLIDER_CompleteList();
    }, [])

  return (
    <ReactCaroussel 
    slidesToShow={1} 
    slidesToScroll={1} 
    infinite={true}
    autoplay={true}
    speed={4} // 2s
    display={{ 
        arrows: true, 
        dots: false 
        }}        
    >

    {slides.map((slide) => (
        <Slide key={slide.id} slide={slide} />
        ))}
    </ReactCaroussel>
  );
}
