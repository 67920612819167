import React, { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';

import {
    Box,
    Button,
    ButtonGroup,
    Card,
    CardHeader,
    CardMedia,
    CardContent,
    Divider,
    Grid,
    Tooltip,
    Typography
} from '@mui/material';

import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import AppShortcutOutlinedIcon from '@mui/icons-material/AppShortcutOutlined';
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import InputOutlinedIcon from '@mui/icons-material/InputOutlined';

import { gridSpacing } from '../config';
import { useTheme } from '@mui/material/styles';

import logo from '../assets/images/buscatrasterologo.webp';

import { useDispatch } from 'react-redux';
import * as actionTypes from '../store/actions';

/* LLAMADAS DE SERVICIOS API */
import SiteService from '../services/SiteService';

export default function PropertyCard(props) {
  const theme = useTheme();
  const [isViewMore, setIsViewMore] = useState(false);
  const [isPropertyEmail, setIsPropertyEmail] = useState(false);

  const dispatch = useDispatch();

  const handleEmailClick = async () => {
    dispatch({ type: actionTypes.SET_PROPERTYID, propertyID: props.property.id });
    setIsPropertyEmail(true);
  };

  const handleWhatsappClick = () => {
    //dispatch({ type: actionTypes.SET_PROPERTYID, propertyID: props.property.id });

    //setIsPropertyEmail(true);
  };

  const handleAccessViewMoreClick = async () => {
    dispatch({ type: actionTypes.SET_PROPERTYID, propertyID: props.property.id });

    try {
      await SiteService.OPERATIONLOG_Register({
          type: "property",
          action: "view",
          associated_id: props.property.id,
          country_id: props.property.country_id,
          state_id: props.property.state_id,
          city_id: props.property.city_id,
          material_id: 0,
          total_mts3_max: 0,
          construction_min: 0,
          commercialoperation_id: 0,
          price_max: 0
        });
  } catch (err) {
      const errorMessage = "Error: " + err.message;
      console.log(errorMessage);
  }

    setIsViewMore(true);
  };

  /*
      <img src={logo} alt="Logo" width="80px" height="80px"/>
                <img src={props.property.image_predetermined[0].image} alt="Imagen Predeterminada" width="100%" height="100%"/>
  */

  if (isViewMore) {
    return  <Navigate to="/property/detail" />
  }

  if (isPropertyEmail) {
    return  <Navigate to="/property/email" />
  }
                                          
  return (
    <Card 
      elevation={0} 
      sx={{ 
        width:"100%", 
        maxWidth: "100%",
      }}>
      <CardContent>
        <CardHeader>

        </CardHeader>
        <Grid container spacing={gridSpacing}>
            <Grid item sm={4} xs={12}>
              <CardMedia
                sx={{ 
                  height: {
                    xs: '100px',
                    md: '250px'
                  },
                  width: '100%'
                }}
                image={ props.property.image_predetermined[0].image }
                title={ props.property.image_predetermined[0].name }
              >
              </CardMedia>
            </Grid> 
            <Grid item sm={8} xs={12}>
                <Grid container>                  
                  <Grid item xs={12}>
                    <Typography variant='subtitle2' color='primary'> 
                      { props.property.address },{ props.property.city.name }, { props.property.state.name }, { props.property.country.name }  
                    </Typography > 
                    <Divider />
                  </Grid>

                  <Grid item xs={12}>
                    <Grid container>
                    <Typography variant="caption" color='black' fontWeight={300} fontSize={16}>{ props.property.commercialoperation.name }</Typography>
                    &nbsp;
                    <Typography variant="h5" color='black' fontWeight={700}>{ props.property.price }</Typography>
                    &nbsp;
                    <Typography variant="overline" color="black" fontWeight={700}>{ props.property.currency }</Typography>
                    </Grid> 
                  </Grid> 

                  <Grid item xs={12}>
                    <Grid container>
                      <Typography variant="caption" color='black' fontWeight={300} fontSize={16}>{ props.property.total_mts3 }</Typography>
                      &nbsp;
                      <Typography variant="caption" color="black" fontWeight={300}>mts<sup>3</sup></Typography>
                      &nbsp;
                      <Typography variant="caption" color="black" fontWeight={300} fontSize={16}>{ props.property.material.name }</Typography>
                    </Grid> 
                  </Grid> 

                  <Grid item xs={12}
                      sx={{ 
                        height: {
                          xs: '100%',
                          md: '110px'
                        },
                        width: '100%'
                      }}
                  >
                    <Grid container>
                      <Typography variant="body2" color='black'>{ props.property.description.slice(0, 200) + "..." }</Typography>
                    </Grid> 
                  </Grid> 

                </Grid> 

                <Divider />
                <Grid container>
                  <Grid item xs={6}>
                    <Box mt={2}  display="flex" justifyContent="flex-start">
                      <ButtonGroup size="small" aria-label="button group" variant="text" color="primary">
                        <Tooltip title={ props.property.user.email }>
                          <Button aria-label="Contactar" onClick={ handleEmailClick }><EmailOutlinedIcon fontSize='small'/></Button>
                        </Tooltip>
                        <Tooltip title={ props.property.user.phone }>
                          <Button aria-label="Whatsapp" onClick={ handleWhatsappClick }><AppShortcutOutlinedIcon fontSize='small'/></Button>
                        </Tooltip>
                      </ButtonGroup>
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box mt={2} display="flex" justifyContent="flex-end">
                      <ButtonGroup size="small" aria-label="button group" variant="text" color="primary">
                        <Tooltip title="Compartir">
                          <Button aria-label="compartir" ><FavoriteBorderOutlinedIcon fontSize='small'/></Button>
                        </Tooltip>
                        <Tooltip title="Ver más ...">
                            <Button aria-label="vermas" onClick={ handleAccessViewMoreClick }><InputOutlinedIcon fontSize='large'/></Button>
                        </Tooltip>
                      </ButtonGroup>
                    </Box>
                  </Grid>
                </Grid>
            </Grid> 
        </Grid>
      </CardContent>
    </Card>
  );
}
