import React, {useState, useEffect} from 'react';
import {  
    Alert,
    Box,
    Button,
    Snackbar,
    Stack,
    TextField
} from '@mui/material'
import Title from './Title'
import Paragraph from './Paragraph'

import { useTheme } from '@mui/material/styles';


import { useFormik } from 'formik';
import { object, string,  } from "yup";

import { store } from '../store';
import Loader from './Loader/Loader';

/* LLAMADAS DE SERVICIOS API */
import SiteService from '../services/SiteService';

import logo from '../assets/images/buscatrasterologo_confondo.webp';
import { Navigate } from 'react-router-dom';

const ParticularPropertyEmail = () => {
    const theme = useTheme();
    const [open, setOpen] = React.useState(false);
    const [alerta, setAlerta] = React.useState({
      tipo: 'error',
      mensaje: ''
    });
    const [isLoading, setIsLoading] = useState(true);
    const [isReturnPropertyDetail, setIsReturnPropertyDetail] = useState(false);

    const propertyID = store.getState().currentSession.propertyID;
  
    const [property, setProperty] = React.useState({
      id: 1,
      title: "Prueba Uno",
      seo: "prueba-uno",
      country_id: 207,
      state_id: 5102,
      city_id: 32602,
      address: "Cucha Cucha 1520",
      latitude: "-32.42054050",
      longitude: "-63.22481370",
      description: "Probando",
      material_id: 1,
      total_mts3: 70,
      construction: "2024-04-19 00:00:00",
      commercialoperation_id: 1,
      currency: "EUR",
      price: "360",
      link_video: null,
      published: 1,
      outstanding: 1,
      user_id: 1,
      created_at: "2024-04-19T16:18:16.000000Z",
      updated_at: "2024-05-15T21:28:04.000000Z",
      country: {
          id: 207,
          name: "Spain",
          numeric_code: "724",
          currency: "EUR",
          currency_name: "Euro",
          currency_symbol: "€",
      },
      state: {
          id: 5102,
          name: "Barcelona",
          country_id: 207,
      },
      city: {
          id: 32602,
          name: "Badalona",
      },
      material: {
          id: 1,
          name: "Ladrillos",
          seo: "ladrillos",
      },
      commercialoperation: {
          id: 1,
          name: "Alquiler",
          seo: "alquiler",
      },
      user: {
          id: 1,
          first_name: "Gabriel",
          last_name: "Toscano",
          phone: "+54 9 11 6293-7778",
          email: "gabrieltoscano@gmail.com",
      },
      image_predetermined: [
          {
              id: 13,
              property_id: 1,
              name: "IMG-20240419-WA0002.jpg",
              ordering: 0,
              image: "fffff"
          }
      ]
    });
  
    const [images, setImages] = useState([]);

    let formikInitialData= {
        type:"contact",
        email: "",
        name: "",
        phone: "",
        subject: "",
        content:"",
      };

    const formikLabel = {
        email: "Correo Electrónico",
        name: "Nombre Completo",
        phone: "Teléfono Celular",
        subject: "Asunto",
        content: "Contenido",
    };
        
    const formikValidationSchema = object({
        email: string().email('Debe ser un correo electrónico válido').max(255).required('Se requiere la dirección de correo'),
        name: string().matches(/^[A-Za-zñÑáéíóúÁÉÍÓÚ.1234567890\s]{3,}$/, 'El nombre debe contener al menos 3 letras').max(255).required('Se requiere el nombre completo'),
        phone: string().matches(/^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/, 'Debe ser un Teléfono Celular válido').required('Se requiere el Teléfono Celular'),
        subject: string().max(255).required('Se requiere el asunto'),
        content: string().required('Se requiere el mensaje'),
    });

    /* FORMIK: DEFINICION */
    const label = formikLabel;
    const {handleSubmit, handleChange, values, errors, touched, resetForm} = useFormik({
        initialValues: formikInitialData,
        validationSchema: formikValidationSchema,
        enableReinitialize: true,
        onSubmit: ( data, actions ) => { onSubmitData(data, actions) }
    });


//    const handleSubmit = async (event) => {
    const onSubmitData = async (data, actions) => {

        try {
            const response = await SiteService.SENDMAIL({
                type: "property",
                receiver: property.user.email,
                email: data.email,
                name: data.name,
                phone: data.phone,
                subject: data.subject,
                content: data.content
            });

            if (response.status) {
                setAlerta({
                  tipo: "success",
                  mensaje: response.datos.message
                });
            } else {
                setAlerta({
                  tipo: "error",
                  mensaje: response.datos.message
                })
            }

            await SiteService.OPERATIONLOG_Register({
                type: "property",
                action: "email",
                associated_id: property.id,
                country_id: property.country_id,
                state_id: property.state_id,
                city_id: property.city_id,
                material_id: 0,
                total_mts3_max: 0,
                construction_min: 0,
                commercialoperation_id: property.commercialoperation_id,
                price_max: 0,
                object: JSON.stringify({
                    email: data.email,
                    name: data.name,
                    phone: data.phone,
                    subject: data.subject,
                    content: data.content
                })
            });
          
            setOpen(true);
        } catch (err) {
            const errorMessage = "Error: " + err.message;
            console.log(errorMessage);
        }

          actions.setSubmitting(false);
          resetForm();
          setIsReturnPropertyDetail(true);
    }


    const handleClose = () => {
        setOpen(false);
    };

    const PROPERTY_GetID = async() => {
      
        try {
          const response = await SiteService.PROPERTY_GetID(propertyID);
    
          setProperty(response.datos.data);
  /*
          console.log("=========================================================");
          console.log("Horario  => ", new Date());
          console.log("propertyID => ", propertyID);
          console.log("response => ", response);
          console.log("selectPosition => ", selectPosition);
          console.log("=========================================================");
   */ 
    
        } catch (err) {
          const errorMessage = "Error: " + err.message;
          console.log(errorMessage);
        }
    
      };
  
    const IMAGE_FindByPropertyID = async() => {
        let imagesRead = [];
        
        try {
          const response = await SiteService.IMAGE_FindByPropertyID(propertyID);
    
          if (response.datos.data.length > 0) {
            response.datos.data.forEach(i => {
              imagesRead.push(
                {
                  original : i.image,
                  thumbnail : i.image,
                },
              )
            });
            setImages(imagesRead);
          } else {
            setImages([{
              original: { logo },
              thumbnail: { logo }
            }]);
  
          }
  /*        
          console.log("=========================================================");
          console.log("Horario    => ", new Date());
          console.log("propertyID => ", propertyID);
          console.log("response   => ", response);
          console.log("images     => ", images);
          console.log("=========================================================");
  */  
        } catch (err) {
          const errorMessage = "Error: " + err.message;
          console.log(errorMessage);
        }

    }
  
    /* SE EJECTURA UNA VEZ AL INGRESAR */
    useEffect(() => {
        PROPERTY_GetID();
        IMAGE_FindByPropertyID();    
        setIsLoading(false);
    }, []);

    useEffect( () => {
        values.subject = "Estoy interesado en " + property.title + " [" + property.id + "]";
        values.content = "\n\nDatos Correspondientes: \n" + 
            property.title + " [" + property.id + "]" + "\n" +
            property.address + "\n" + property.city.name + "\n" + property.state.name + "\n" + property.country.name + "\n";
    }, [property])    

    if (isLoading) return <Loader />;

    if (isReturnPropertyDetail) {
        return  <Navigate to="/property/detail" />
    }

    return (
        <>
            <Stack 
            component='section'
            direction="column"
            justifyContent= 'center'
            alignItems='center'
            sx={{
                py: 10,
                px: 2,
            }}
            >
                <Title 
                text={
                    'Quiero saber más'
                    } 
                textAlign={'center'}
                />
                <Paragraph 
                text={
                    'Estás interesado en esta propiedad, contáctacte con nosotros. En breve nos comunicaremos contigo.'
                }
                maxWidth = {'sm'}
                mx={0}
                textAlign={'center'}
                />

                <Box 
                component="form" 
                noValidate 
                onSubmit={handleSubmit} 
                sx={{ 
                    mt: 1,
                    py: 2
                }}>

                    <TextField name="email" 
                        type="string"
                        required 
                        label={label.email}
                        variant="outlined" 
                        sx={{ mt: theme.spacing(3), mb: theme.spacing(1) }}
                        fullWidth
                        onChange={handleChange}
                        value={values.email}
                        error={touched.email && errors.email !== undefined}
                        helperText={errors.email}
                    />

                    <TextField name="name" 
                        type="string"
                        required 
                        label={label.name}
                        variant="outlined" 
                        sx={{ mt: theme.spacing(3), mb: theme.spacing(1) }}
                        margin="normal"
                        fullWidth
                        onChange={handleChange}
                        value={values.name}
                        error={touched.name && errors.name !== undefined}
                        helperText={errors.name}
                    />

                    <TextField name="phone" 
                        type="string"
                        required 
                        label={label.phone}
                        variant="outlined" 
                        sx={{ mt: theme.spacing(3), mb: theme.spacing(1) }}
                        margin="normal"
                        fullWidth
                        onChange={handleChange}
                        value={values.phone}
                        error={touched.phone && errors.phone !== undefined}
                        helperText={errors.phone}
                    />

                    <TextField name="subject" 
                        type="string"
                        required 
                        label={label.subject}
                        variant="outlined" 
                        sx={{ mt: theme.spacing(3), mb: theme.spacing(1) }}
                        margin="normal"
                        fullWidth
                        onChange={handleChange}
                        value={values.subject}
                        error={touched.subject && errors.subject !== undefined}
                        helperText={errors.subject}
                    />

                    <TextField name="content" 
                        type="string"
                        required 
                        label={label.content}
                        variant="outlined" 
                        sx={{ mt: theme.spacing(3), mb: theme.spacing(1) }}
                        margin="normal"
                        fullWidth
                        multiline
                        rows={4}
                        onChange={handleChange}
                        value={values.content}
                        error={touched.content && errors.content !== undefined}
                        helperText={errors.content}
                    />

                    <Button 
                    variant="contained" 
                    fullWidth
                    type="submit"
                    size="medium"
                    sx= {{ 
                        fontSize: '0.9rem',
                        textTransform: 'capitalize', 
                        py: 2,
                        mt: 3, 
                        mb: 2,
                        borderRadius: 0,
                        backgroundColor: 'primary', //'#14192d',
                        "&:hover": {
                            backgroundColor: 'terciary'//, '#1e2a5a',
                        }
                    }}
                    >
                        Enviar
                    </Button>
                </Box>
            </Stack>
            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}
                anchorOrigin={{
                vertical: "bottom",
                horizontal: "right"
                }}>
                <Alert
                    onClose={handleClose}
                    severity={alerta.tipo}
                    variant="filled"
                    sx={{ width: '100%' }}
                    >
                    {alerta.mensaje}
                </Alert>
            </Snackbar>
        </>
    )
}

export default ParticularPropertyEmail;