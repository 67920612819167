// src/redux/store.js
import { configureStore } from "@reduxjs/toolkit";
import reducer from './reducer';
import storage from 'redux-persist/lib/storage';
import { 
  persistReducer, 
  persistStore,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER 
} from 'redux-persist';
import { encryptTransform } from 'redux-persist-transform-encrypt';

const encryptor = encryptTransform({
  secretKey: "GFD3v3l0p3rs",
  onError: function (error) {
      console.log(error);
  }
})

const persistConfig = {
  key: 'root',
  storage,
  whiteList: ['reducer'],
  transforms: [encryptor],
}

const persistedReducer = persistReducer(persistConfig, reducer)

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
  getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    },
  }),  
  devTools: process.env.NODE_ENV !== 'production',
})

export const persistor = persistStore(store)