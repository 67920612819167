import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import {
  Typography,
} from '@mui/material';

import { useTheme } from '@mui/material/styles';

import Breadcrumb from '../components/Breadcrumb';

import Carousel from '../components/Carousel';
import PropertyDetailComponent from '../components/PropertyDetail';

const PropertyDetail = () => {
  const theme = useTheme();
  const [isChangeResult, setIsChangeResult] = useState(true);

  return (
    <>
      <Breadcrumb divider={false}>
        <Typography component={Link} to="/" variant="h4" color="inherit" className="link-breadcrumb">
          Home
        </Typography>
        <Typography component={Link} to="/property" variant="h4" color="inherit" className="link-breadcrumb">
          Propiedades
        </Typography>
        <Typography variant="subtitle2" color="primary" className="link-breadcrumb">
          Propiedad Detalle
        </Typography>
      </Breadcrumb>
      <PropertyDetailComponent />
      <Carousel page={"PropertyDetail"} />
    </>
  )
}

export default PropertyDetail