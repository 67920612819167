import React, {useState, useEffect, useRef } from 'react';
import { Navigate } from 'react-router-dom';
import { FloatingWhatsApp } from 'react-floating-whatsapp'
import ReactPlayer from "react-player";

import {
  Button,
  Card,
  CardHeader,
  CardContent,
  Divider,
  Grid,
  Tooltip,
  Typography
} from '@mui/material';

import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import WarehouseOutlinedIcon from '@mui/icons-material/WarehouseOutlined';
import SquareFootOutlinedIcon from '@mui/icons-material/SquareFootOutlined';

import Paragraph from './Paragraph'

import { gridSpacing } from '../config';
import { useTheme } from '@mui/material/styles';

import Loader from './Loader/Loader';

import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";

import Maps from "./Maps";
import "leaflet/dist/leaflet.css";

import { store } from '../store';
/* LLAMARDAS DE SERVICIOS API */
import SiteService from '../services/SiteService';

import logo from '../assets/images/buscatrasterologo_confondo.webp';

export default function PropertyDetail() {
  const videoRef = useRef();

  const theme = useTheme();
  const [isLoading, setIsLoading] = useState(true);
  const propertyID = store.getState().currentSession.propertyID;
  const [isLinkVideo, setIsLinkVideo] = useState(false);

  const [property, setProperty] = React.useState({
    id: 1,
    title: "Prueba Uno",
    seo: "prueba-uno",
    country_id: 207,
    state_id: 5102,
    city_id: 32602,
    address: "Cucha Cucha 1520",
    latitude: "-32.42054050",
    longitude: "-63.22481370",
    description: "Probando",
    material_id: 1,
    total_mts3: 70,
    construction: "2024-04-19 00:00:00",
    commercialoperation_id: 1,
    currency: "EUR",
    price: "360",
    link_video: null,
    published: 1,
    outstanding: 1,
    user_id: 1,
    created_at: "2024-04-19T16:18:16.000000Z",
    updated_at: "2024-05-15T21:28:04.000000Z",
    country: {
        id: 207,
        name: "Spain",
        numeric_code: "724",
        currency: "EUR",
        currency_name: "Euro",
        currency_symbol: "€",
    },
    state: {
        id: 5102,
        name: "Barcelona",
        country_id: 207,
    },
    city: {
        id: 32602,
        name: "Badalona",
    },
    material: {
        id: 1,
        name: "Ladrillos",
        seo: "ladrillos",
    },
    commercialoperation: {
        id: 1,
        name: "Alquiler",
        seo: "alquiler",
    },
    user: {
        id: 1,
        first_name: "Gabriel",
        last_name: "Toscano",
        phone: "+54 9 11 6293-7778",
        email: "gabrieltoscano@gmail.com",
    },
    image_predetermined: [
        {
            id: 13,
            property_id: 1,
            name: "IMG-20240419-WA0002.jpg",
            ordering: 0,
            image: "fffff"
        }
    ]
  });
  
  const [images, setImages] = useState([]);
  const [selectPosition, setSelectPosition] = useState(null);
  const [isSendEmail, setIsSendEmail] = useState(false);


  const PROPERTY_GetID = async() => {
      
    try {
      const response = await SiteService.PROPERTY_GetID(propertyID);

      setProperty(response.datos.data);
/*
      console.log("=========================================================");
      console.log("Horario  => ", new Date());
      console.log("propertyID => ", propertyID);
      console.log("response => ", response);
      console.log("selectPosition => ", selectPosition);
      console.log("=========================================================");
*/ 

    } catch (err) {
      const errorMessage = "Error: " + err.message;
      console.log(errorMessage);
    }

  };
  
  const IMAGE_FindByPropertyID = async() => {
    let imagesRead = [];
    
    try {
      const response = await SiteService.IMAGE_FindByPropertyID(propertyID);

      if (response.datos.data.length > 0) {
        response.datos.data.forEach(i => {
          imagesRead.push(
            {
              original : i.image,
              thumbnail : i.image,
            },
          )
        });
        setImages(imagesRead);
      } else {
        setImages([{
          original: { logo },
          thumbnail: { logo }
        }]);

      }
/*        
      console.log("=========================================================");
      console.log("Horario    => ", new Date());
      console.log("propertyID => ", propertyID);
      console.log("response   => ", response);
      console.log("images     => ", images);
      console.log("=========================================================");
*/  
    } catch (err) {
      const errorMessage = "Error: " + err.message;
      console.log(errorMessage);
    }

  }
  
  const handleButtonEmail = () => {
    setIsSendEmail(true);
  };

  const isEmpty = (value) => {
    return (value == null || (typeof value === "string" && value.trim().length === 0));
  }
  
   const handleSendWhatsApp = async (state) => {

    /*
    console.log("**********************************************");
    console.log(state);
    console.log(state.msg);
    console.log("**********************************************");
    */
    await SiteService.OPERATIONLOG_Register({
      type: "property",
      action: "whatsapp",
      associated_id: property.id,
      country_id: property.country_id,
      state_id: property.state_id,
      city_id: property.city_id,
      material_id: 0,
      total_mts3_max: 0,
      construction_min: 0,
      commercialoperation_id: 0,
      price_max: 0
    });

    /*
    let msg = this.state.msg;
    let mobile= this.state.mobile_no;

    if (mobile) {
          if (msg) {
              let url = 'whatsapp://send?phone=62' + this.state.mobile_no + '&text=' + this.state.msg;
              Linking.openURL(url).then(() => {
                    console.log('WhatasApp Opened');
              }).catch(() => {
                    alert('Make Sure whatsapp is installed on your device');
              });
          }else{
              console.log('Please insert message to send');
          }
    }else{
          console.log('Please insert mobile no');
    }
      */
  };


  /* SE EJECTURA UNA VEZ AL INGRESAR */
  useEffect(() => {

    PROPERTY_GetID();
    IMAGE_FindByPropertyID();    
    setIsLoading(false);
/*
    console.log("***************************************************");
    console.log("Horario  => ", new Date());
    console.log("property => ", property);
    console.log("***************************************************");
*/
  }, []);

  useEffect( () => {
    setSelectPosition({
      lat: property.latitude,
      lon: property.longitude
    });
/*
      console.log("***************************************************");
      console.log("Horario  => ", new Date());
      console.log("selectPosition => ", selectPosition);
      console.log("***************************************************");
*/

    if ( isEmpty(property.link_video) ) {
      setIsLinkVideo(false);
    } else {
      setIsLinkVideo(true);
    }

  }, [property])
    
  if (isSendEmail) {
    return  <Navigate to="/property/email" />
  }

  if (isLoading) return <Loader />;
  
  if (!isLoading) return (
    <>
      <Grid container spacing={gridSpacing}>
        <Grid item xs={12}
          sx={{ mt: theme.spacing(3), mb: theme.spacing(3), ml: theme.spacing(10), mr: theme.spacing(10) }}
        >
          <Typography variant='h2' color='primary'> 
            { property.title }
          </Typography > 
          <Typography variant='h4' color='primary'> 
            <LocationOnOutlinedIcon fontSize='Large'/>
            { property.address },{ property.city.name }, { property.state.name }, { property.country.name }  
          </Typography > 
        </Grid>
        <Grid item xs={12}
          sx={{ mt: theme.spacing(3), mb: theme.spacing(3), ml: theme.spacing(10), mr: theme.spacing(10) }}
        >
          <Grid container spacing={gridSpacing}>
            <Grid item xs={8}>
              <Card 
              elevation={0} 
              sx={{ 
                width:"100%", 
                maxWidth: "100%",
              }}>
                <CardHeader
                  title={
                    <Typography variant="h4">
                      Fotos
                    </Typography>
                  }
                />
                <Divider variant='middle'/>
                <CardContent>

                      <ImageGallery
                        items={images}
                        showBullets={true}
                        showIndex={true}
                        showThumbnails={true}
                        infinite={true}
                        lazyLoad={true}
                        showPlayButton={true}
                        showNav={true}
                        showFullscreenButton={true}
                        thumbnailPosition={"left"}
                        disableThumbnailScroll={true}
                      />
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={4}>
              <Grid container spacing={gridSpacing}>
                <Grid item xs={12}>

                  <Card 
                    elevation={0} 
                    sx={{ 
                      width:"100%", 
                      maxWidth: "100%",
                  }}>
                    <CardHeader
                      title={
                        <Typography variant="h4">
                          { property.commercialoperation.name }
                        </Typography>
                      }
                    />
                    <Divider variant='middle'/>
                    <CardContent>
                      <Typography variant="h4">
                        { property.currency } { property.price }
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>

                <Grid item xs={12}>
                  <Card 
                    elevation={0} 
                    sx={{ 
                      width:"100%", 
                      maxWidth: "100%",
                  }}>
                    <CardHeader
                      title={
                        <Typography variant="h4">
                          Características
                        </Typography>
                      }
                    />
                    <Divider variant='middle'/>
                    <CardContent>
                      <Grid container spacing={gridSpacing}>
                        <Grid item xs={4}>
                          <WarehouseOutlinedIcon fontSize='large'/>
                        </Grid>
                        <Grid item xs={8}>
                          <Typography variant="h4">
                            { property.material.name }
                          </Typography>
                        </Grid>
                        <Grid item xs={4}>
                          <SquareFootOutlinedIcon fontSize='large'/>
                        </Grid>
                        <Grid item xs={8}>
                          <Typography variant="h4">
                            { property.total_mts3 } mts<sup>3</sup>
                          </Typography>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12}>
                  <Card 
                    elevation={0} 
                    sx={{ 
                      width:"100%", 
                      maxWidth: "100%",
                  }}>
                  <CardHeader
                    title={
                      <Typography variant="h4">
                        Envíanos un Correo
                      </Typography>
                    }
                  />
                  <Divider variant='middle'/>
                    <CardContent>
                      <Grid container spacing={gridSpacing}>
                        <Grid item xs={12}>
                          <Tooltip title="Contactarse por Correo">
                            <Button aria-label="buscar" variant="contained" size="large" fullWidth 
                              onClick={ handleButtonEmail }
                            >
                              <EmailOutlinedIcon fontSize='Large'/>
                            </Button>
                          </Tooltip>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>


              </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}
          sx={{ mt: theme.spacing(3), mb: theme.spacing(3), ml: theme.spacing(10), mr: theme.spacing(10) }}
        >
          <Grid container spacing={gridSpacing}>
            <Grid item xs={12}>

              <Card 
                elevation={0} 
                sx={{ 
                  width:"100%", 
                  maxWidth: "100%",
              }}>
                <CardHeader
                  title={
                    <Typography variant="h4">
                      Descripción
                    </Typography>
                  }
                />
                <Divider variant='middle'/>
                <CardContent>
                  <Grid container spacing={gridSpacing}>
                    <Grid item sm={12} xs={12}>

                      <Paragraph
                        text={property.description}
                      />


                    </Grid> 
                  </Grid>
                </CardContent>
              </Card>


            </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}
          sx={{ mt: theme.spacing(3), mb: theme.spacing(3), ml: theme.spacing(10), mr: theme.spacing(10) }}
        >
          <Grid container spacing={gridSpacing}>
            <Grid item xs={12}>

              <Card 
                elevation={0} 
                sx={{ 
                  width:"100%", 
                  maxWidth: "100%",
              }}>
                <CardHeader
                  title={
                    <Typography variant="h4">
                      Ubicación
                    </Typography>
                  }
                />
                <Divider variant='middle'/>
                <CardContent>
                  <Grid container spacing={gridSpacing}>
                    <Grid item sm={12} xs={12}>

                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          height: "60vh",
                          width: "100%",
                        }}
                      >
                        <div style={{ height: "60vh", width: "100%"  }}>
                          <Maps selectPosition={selectPosition}/>
                        </div>
                      </div>


                    </Grid> 
                  </Grid>
                </CardContent>
              </Card>

            </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}
          sx={{ mt: theme.spacing(3), mb: theme.spacing(3), ml: theme.spacing(10), mr: theme.spacing(10), display: ( isLinkVideo ? 'block': 'none' ) }}

        >
          <Grid container spacing={gridSpacing}>
            <Grid item xs={12}>

              <Card 
                elevation={0} 
                sx={{ 
                  width:"100%", 
                  maxWidth: "100%",
              }}>
                <CardHeader
                  title={
                    <Typography variant="h4">
                      Video 360º
                    </Typography>
                  }
                />
                <Divider variant='middle'/>
                <CardContent>
                  <Grid container spacing={gridSpacing}>
                    <Grid item sm={12} xs={12}>

                    <div ref={videoRef}>
                      <ReactPlayer
                        width="100%"
                        height="600px"
                        controls={true}
                        url={ property.link_video }
                        config={{ file: { attributes: { controlsList: "nodownload" } } }}
                      />
                    </div>


                    </Grid> 
                  </Grid>
                </CardContent>
              </Card>

            </Grid>
        </Grid>
      </Grid>
    </Grid>
    <FloatingWhatsApp 
      accountName={property.user.first_name}
      phoneNumber={property.user.phone}
      statusMessage='CONTÁCTATE CON NOSOTROS'
      chatboxHeight={500}
      chatMessage = { 
        `¡Hola! 🤝 \n¿Cómo podemos ayudarte?"`  
      }
      placeholder = { `Estoy interesado en ` + property.title + " [" + property.id + "] ?"  }
      onClick={ handleSendWhatsApp }

    />

    </>
  );
}

  /*
                  chatMessage = { `¡Hola! 🤝 \n¿Está intereado en ` + property.title + " [" + property.id + "] ?"  }
                  chatMessage = { `¡Hola! 🤝 \n¿Está intereado en ` + property.title + " [" + property.id + "] ?"  }

                  placeholder = "Ingrese su mensaje ..."

  */