// action - state management
import * as actionTypes from './actions';

// ==============================|| PARAMETERS REDUCER ||============================== //

export const initialState = {
  parameters: {}
};

const parametersReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_PARAMETERS:
      return {
        ...state,
        parameters: action.parameters
      };
    default:
      return state;
  }
};

export default parametersReducer;
