import React, {useEffect, useState} from 'react'
//import GetInTouch from '../components/GetInTouch';
//import GetStarted from '../components/GetStarted';
import Header from '../components/Header';

import Slider from '../components/Slider';
import CarouselHome from '../components/CarouselHome';
import Outstanding from '../components/Outstanding';
import SearchByCity from '../components/Forms/SearchByCity';

const Home = () => {
  return (
    <>
      <Header />
      <Outstanding />
      <Slider />
      <SearchByCity />
      <CarouselHome />
    </>

  )
}

export default Home

/*
      <GetStarted />
      <GetInTouch />

*/