import React, { useState, useEffect } from 'react';

import {
  Box,
  Grid,
  Pagination,
  styled,
  Toolbar,
  Typography
} from '@mui/material';

import { gridSpacing } from '../config';
import { useTheme } from '@mui/material/styles';
import Loader from '../components/Loader/Loader';

import PropertyCard from './PropertyCard';

import logo from '../assets/images/destacados.webp';

/* LLAMARDAS DE SERVICIOS API */
import SiteService from '../services/SiteService';

// personalizacao
const StyledToolbar = styled(Toolbar) ({
  display: 'flex',
  justifyContent: 'space-between',
  backgroundColor: 'white', 

});


const firstIndex = 0;

export default function Outstanding() {
  const theme = useTheme();
  const [isLoading, setIsLoading] = useState(true);
  const [properties, setProperties] = useState([]);


  /* VARIABLES DE PAGINACION */
  const [pageSize, setPageSize] = useState(3);
  const [page, setPage] = useState(1);
  const [data, setData] = useState(properties.slice(firstIndex, pageSize));  

  useEffect(() => {
    setData(properties.slice(0, pageSize));
  }, [pageSize]);

  const handleChange = (event, value) => {
    setPage(value);
    setData(properties.slice(firstIndex + pageSize * (value - 1), pageSize * value));
  };

  //Change width
  const changeWidth = (e) => {
    setPageSize(parseInt(e.target.value, 10));
  };


  const PROPERTY_ByOutstanding = async () => {
    
    try {
      const response = await SiteService.PROPERTY_ByOutstanding();
/*
      console.log("***********************************************");
      console.log("Horario: ", new Date());
      console.log("response.datos.data ==>> ", response.datos.data);
      console.log("***********************************************");
*/
      setProperties(response.datos.data);

    } catch (err) {
      const errorMessage = "Error: " + err.message;
      console.log(errorMessage);
    }

  }

  useEffect(() => {
    PROPERTY_ByOutstanding();

    setIsLoading(false);
    //console.log("properties ==>> ", properties);
    
  }, []);

  if (isLoading) return <Loader />;

  if (properties.length > 0 ) {
    return (
      <>
        <StyledToolbar>
            <Typography
            variant="h6"
            component="h2"

        >
            <Box>
                <img src={logo} alt="Logo" width="100%" height="100%"/>
            </Box>
            </Typography>
            <Box sx={{display: { xs: 'block', sm: 'none' } }}>
            </Box>
        </StyledToolbar>

        <Grid container spacing={gridSpacing}
          sx={{backgroundColor:"white"}}
        >
          {properties.map((property, index) => (
            <Grid key={ index } item xs={6}>
              <PropertyCard property={ property } />
            </Grid>
          ))}
        </Grid>
        <Box display={'flex'} justifyContent={'center'} alignItems={'flex-center'} sx={{ pt: theme.spacing(3), pb: theme.spacing(1), backgroundColor: 'white'}}>
          <Pagination
            count={Math.ceil(properties.length / pageSize)}
            page={page}
            onChange={handleChange}
            color="primary"
          />
        </Box>
      </>
    );
  } else {
    return (
      <>
        <Grid container spacing={gridSpacing}
          sx={{backgroundColor:"white"}}
        >
          <Grid item xs={12}>
              <Grid container alignContent={"center"} justifyContent={"center"} alignItems={'center'}>
                <Typography variant='h3'>No se ha encontrado la información correspondiente.</Typography>
              </Grid>
            </Grid>
        </Grid>
      </>
    );
  }
}

/*
      <Typography>Página: {page}</Typography>
      <Pagination
        count={Math.ceil(properties.length / pageSize)}
        page={page}
        onChange={handleChange}
      />
      <input type="number" onChange={changeWidth} value={pageSize} /></>


*/