import ReactCaroussel from "react-caroussel";
import { useMediaQuery } from 'react-responsive';
import "react-caroussel/dist/index.css";

import { 
    Box, 
    styled, 
} from "@mui/material";
import { useTheme } from '@mui/material/styles';

/* LLAMADAS DE SERVICIOS API */
import SiteService from '../services/SiteService';
import { useEffect, useState } from "react";

const Slide = ({slide}) => {
    const theme = useTheme();

    const CustomSlideBox = styled(Box) (({ theme }) => ({
        height: {
            xs: '250px',
            md: '500px'
        },
        width: {
            xs: '250px',
            md: '500px'
        },
        display: 'flex',
    }));

    return (
        <CustomSlideBox
        // Imagen de Fondo
        sx={{
            backgroundImage: `url(${slide.image})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: {
                xs: "250px 250px",
                md: "500px 500px"
            },
            height: {
                xs: "250px",
                md: "500px"
            },               
            width: {
                xs: "250px",
                md: "500px"
            },               
        }}
        >
        </CustomSlideBox>
    );
  };

export default function Slider( { page } ) {
    const [slides, setSlides] = useState([]);
    const theme = useTheme();
    const [isMobile, setIsMobile] = useState(false)


    const ADVERTINSINGCAROUSEL_CompleteListByPage = async (page) => {
        try {
        const response = await SiteService.ADVERTINSINGCAROUSEL_CompleteListByPage(page);
        setSlides(response.datos.data);
        } catch (err) {
        const errorMessage = "Error: " + err.message;
        console.log(errorMessage);
        }
    }

    //choose the screen size 
    const handleResize = () => {
        if (window.innerWidth < 720) {
            setIsMobile(true)
        } else {
            setIsMobile(false)
        }
    }

    useEffect(() => {
        ADVERTINSINGCAROUSEL_CompleteListByPage(page);
    }, [])

// create an event listener
    useEffect(() => {
        window.addEventListener("resize", handleResize)
    })

    return (
        <ReactCaroussel
            slidesToShow={ (isMobile? 2: 3) }
            slidesToScroll={(isMobile? 2: 3) }
            infinite={true}
            autoplay={true}
            speed={4} // 2s
            display={{ 
                arrows: true, 
                dots: false 
            }}        
        >

        {slides.map((slide) => (
            <Slide key={slide.id} slide={slide} />
            ))}
        </ReactCaroussel>
    );
}
