import React from 'react'
import {  
    Alert,
    Box,
    Button,
    Snackbar,
    Stack,
    TextField
} from '@mui/material'
import Title from './Title'
import Paragraph from './Paragraph'


import { gridSpacing } from '../config';
import { useTheme, styled } from '@mui/material/styles';


import { useFormik } from 'formik';
import { object, email, string, number, date, boolean } from "yup";

/* LLAMADAS DE SERVICIOS API */
import SiteService from '../services/SiteService';


const Details = () => {
    const theme = useTheme();
    const [open, setOpen] = React.useState(false);
    const [alerta, setAlerta] = React.useState({
      tipo: 'error',
      mensaje: ''
    });
  

    let formikInitialData= {
        type:"contact",
        email: "",
        name: "",
        phone: "",
        subject: "",
        content:"",
      };

    const formikLabel = {
        email: "Correo Electrónico",
        name: "Nombre Completo",
        phone: "Teléfono Celular",
        subject: "Asunto",
        content: "Contenido",
    };
        
    const formikValidationSchema = object({
        email: string().email('Debe ser un correo electrónico válido').max(255).required('Se requiere la dirección de correo'),
        name: string().matches(/^[A-Za-zñÑáéíóúÁÉÍÓÚ.1234567890\s]{3,}$/, 'El nombre debe contener al menos 3 letras').max(255).required('Se requiere el nombre completo'),
        phone: string().matches(/^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/, 'Debe ser un Teléfono Celular válido').required('Se requiere el Teléfono Celular'),
        subject: string().max(255).required('Se requiere el asunto'),
        content: string().required('Se requiere el mensaje'),
    });

    /* FORMIK: DEFINICION */
    const label = formikLabel;
    const {handleSubmit, handleChange, values, errors, touched, isSubmitting, resetForm} = useFormik({
        initialValues: formikInitialData,
        validationSchema: formikValidationSchema,
        enableReinitialize: true,
        onSubmit: ( data, actions ) => { onSubmitData(data, actions) }
    });


//    const handleSubmit = async (event) => {
    const onSubmitData = async (data, actions) => {

        try {
            const response = await SiteService.SENDMAIL({
                type: "contact",
                email: data.email,
                name: data.name,
                phone: data.phone,
                subject: data.subject,
                content: data.content
            });

            if (response.status) {
                setAlerta({
                  tipo: "success",
                  mensaje: response.datos.message
                });
            } else {
                setAlerta({
                    tipo: "error",
                    mensaje: response.datos.message
                })
            }

            await SiteService.OPERATIONLOG_Register({
                type: "contact",
                action: "email",
                object: JSON.stringify({
                    email: data.email,
                    name: data.name,
                    phone: data.phone,
                    subject: data.subject,
                    content: data.content
                })
            });


            setOpen(true);
        } catch (err) {
            const errorMessage = "Error: " + err.message;
            console.log(errorMessage);
        }

          actions.setSubmitting(false);
          resetForm();
    }


    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            <Stack 
            component='section'
            direction="column"
            justifyContent= 'center'
            alignItems='center'
            sx={{
                py: 10,
                px: 2,
            }}
            >
                <Title 
                text={
                    'Contáctenos'
                    } 
                textAlign={'center'}
                />
                <Paragraph 
                text={
                    'Ante cualquier inquietud, contáctacte con nosotros.En breve nos comunicaremos contigo.'
                }
                maxWidth = {'sm'}
                mx={0}
                textAlign={'center'}
                />

                <Box 
                component="form" 
                noValidate 
                onSubmit={handleSubmit} 
                sx={{ 
                    mt: 1,
                    py: 2
                }}>

                    <TextField name="email" 
                        type="string"
                        required 
                        label={label.email}
                        variant="outlined" 
                        sx={{ mt: theme.spacing(3), mb: theme.spacing(1) }}
                        fullWidth
                        onChange={handleChange}
                        value={values.email}
                        error={touched.email && errors.email !== undefined}
                        helperText={errors.email}
                    />

                    <TextField name="name" 
                        type="string"
                        required 
                        label={label.name}
                        variant="outlined" 
                        sx={{ mt: theme.spacing(3), mb: theme.spacing(1) }}
                        margin="normal"
                        fullWidth
                        onChange={handleChange}
                        value={values.name}
                        error={touched.name && errors.name !== undefined}
                        helperText={errors.name}
                    />

                    <TextField name="phone" 
                        type="string"
                        required 
                        label={label.phone}
                        variant="outlined" 
                        sx={{ mt: theme.spacing(3), mb: theme.spacing(1) }}
                        margin="normal"
                        fullWidth
                        onChange={handleChange}
                        value={values.phone}
                        error={touched.phone && errors.phone !== undefined}
                        helperText={errors.phone}
                    />

                    <TextField name="subject" 
                        type="string"
                        required 
                        label={label.subject}
                        variant="outlined" 
                        sx={{ mt: theme.spacing(3), mb: theme.spacing(1) }}
                        margin="normal"
                        fullWidth
                        onChange={handleChange}
                        value={values.subject}
                        error={touched.subject && errors.subject !== undefined}
                        helperText={errors.subject}
                    />

                    <TextField name="content" 
                        type="string"
                        required 
                        label={label.content}
                        variant="outlined" 
                        sx={{ mt: theme.spacing(3), mb: theme.spacing(1) }}
                        margin="normal"
                        fullWidth
                        multiline
                        rows={4}
                        onChange={handleChange}
                        value={values.content}
                        error={touched.content && errors.content !== undefined}
                        helperText={errors.content}
                    />

                    <Button 
                    variant="contained" 
                    fullWidth
                    type="submit"
                    size="medium"
                    sx= {{ 
                        fontSize: '0.9rem',
                        textTransform: 'capitalize', 
                        py: 2,
                        mt: 3, 
                        mb: 2,
                        borderRadius: 0,
                        backgroundColor: 'primary', //'#14192d',
                        "&:hover": {
                            backgroundColor: 'terciary'//, '#1e2a5a',
                        }
                    }}
                    >
                        Enviar
                    </Button>
                </Box>
            </Stack>
            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}
                anchorOrigin={{
                vertical: "bottom",
                horizontal: "right"
                }}>
                <Alert
                    onClose={handleClose}
                    severity={alerta.tipo}
                    variant="filled"
                    sx={{ width: '100%' }}
                    >
                    {alerta.mensaje}
                </Alert>
            </Snackbar>
        </>
    )
}

export default Details;