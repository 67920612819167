import React, { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';

import {
  Box,
  Grid,
  Pagination,
  Typography
} from '@mui/material';

import { gridSpacing } from '../config';
import { useTheme } from '@mui/material/styles';
import Loader from '../components/Loader/Loader';

import PropertyCard from './PropertyCard';
import { store } from '../store';

/* LLAMARDAS DE SERVICIOS API */
import SiteService from '../services/SiteService';

const firstIndex = 0;


export default function Result(props) {
  const theme = useTheme();

  const {isChangeResult, setIsChangeResult} = props;

  const search = store.getState().currentSession.search;
  const [isLoading, setIsLoading] = useState(true);

  const [properties, setProperties] = useState([]);


  /* VARIABLES DE PAGINACION */
  const [pageSize, setPageSize] = useState(3);
  const [page, setPage] = useState(1);
  const [data, setData] = useState(properties.slice(firstIndex, pageSize));  

  useEffect(() => {
    setData(properties.slice(0, pageSize));
  }, [pageSize]);

  const handleChange = (event, value) => {
    setPage(value);
    setData(properties.slice(firstIndex + pageSize * (value - 1), pageSize * value));
  };

  //Change width
  const changeWidth = (e) => {
    setPageSize(parseInt(e.target.value, 10));
  };


  const PROPERTY_BySearchCriteria = async (search) => {
    
    if (isChangeResult) {
      try {
        const response = await SiteService.PROPERTY_BySearchCriteria(search);
        setProperties(response.datos.data);

        setIsChangeResult(false);
      } catch (err) {
        const errorMessage = "Error: " + err.message;
        console.log(errorMessage);
      }
    }

  }

  useEffect(() => {
    PROPERTY_BySearchCriteria(search);

    setIsLoading(false);
    //console.log("properties ==>> ", properties);
    
  }, [isChangeResult]);

  useEffect(() => {
    setData(properties.slice(firstIndex, pageSize));

    setIsLoading(false);
  }, [properties]);

  if (isLoading) return <Loader />;

  if (properties.length > 0 ) {
    return (
      <>
        <Grid container spacing={gridSpacing}>
          {properties.map((property, index) => (
            <Grid key={ index } item xs={12}>
              <Grid container>
                <PropertyCard property={ property } />
              </Grid>
            </Grid>
          ))}
        </Grid>
        <Box display={'flex'} justifyContent={'center'} alignItems={'flex-center'} sx={{ mt: theme.spacing(3), mb: theme.spacing(1) }}>
          <Pagination
            count={Math.ceil(properties.length / pageSize)}
            page={page}
            onChange={handleChange}
            color="primary"
          />
        </Box>
      </>
    );
  } else {
    return (
      <>
        <Grid container spacing={gridSpacing}>
          <Grid item xs={12}>
              <Grid container alignContent={"center"} justifyContent={"center"} alignItems={'center'}>
                <Typography variant='h3'>No se ha encontrado la información correspondiente.</Typography>
              </Grid>
            </Grid>
        </Grid>
      </>
    );
  }
}

/*
      <Typography>Página: {page}</Typography>
      <Pagination
        count={Math.ceil(properties.length / pageSize)}
        page={page}
        onChange={handleChange}
      />
      <input type="number" onChange={changeWidth} value={pageSize} /></>


*/