import React from 'react'
import { Box, Button, styled, Typography } from "@mui/material";
import { Link } from 'react-router-dom'
import { useTheme } from '@mui/material/styles';

//img
//import headerImg from '../assets/pexels-binyamin-mellish-186078.png'
//import headerImg from '../assets/deposito.webp';
import headerImg from '../assets/images/home01.webp';
import Search from './Forms/Search';

const Header = () => {
    const theme = useTheme();
  
    const CustomBox = styled(Box) (({ theme }) => ({
        minHeight: '800px',
        display: 'flex',
        justifyContent: 'center',
        alignContent: 'center',
        // tamaños
        gap: theme.spacing(2),
        paddingTop: theme.spacing(3),

        // Imagen de Fondo
        backgroundImage: `url(${headerImg})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",

        [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
            alignItems: 'center',
            textAlign: 'center',
        }
    }));

    const BoxText = styled(Box) (({ theme }) => ({
        flex: '1',
        paddingTop: theme.spacing(3),
        paddingLeft: theme.spacing(30),
        paddingRight: theme.spacing(30),
        [theme.breakpoints.down('md')]: {
            flex: '2',
            textAlign: 'center',
            paddingLeft: theme.spacing(3),
            paddingRight: theme.spacing(3),
            display: 'block'
        },

    }));

    const BoxSearch = styled(Box) (({ theme }) => ({
        flex: '1',
        paddingTop: theme.spacing(3),
        paddingLeft: theme.spacing(35),
        paddingRight: theme.spacing(35),
        [theme.breakpoints.down('md')]: {
            flex: '2',
            textAlign: 'center',
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
            display: 'block'
        },
        opacity: 0.4, 
        backgroundColor: '#6d429b'
    }));

  return  (
        <CustomBox 
            component='header'
        >
            {/*  Box text  */}
            <BoxText 
                component='section'
            >

                <Typography 
                    variant='h2'
                    component= 'h1'
                    justifyContent={"center"}
                    sx={{ textShadow: '2px 2px 4px rgba(0, 0, 0, 0.25), -2px -2px 4px rgba(0, 0, 0, 0.25)' }}>
                    Publica tu trastero o consigue el que necesitas en el <b>mismo lugar</b>
                </Typography>
                <Search />
            </BoxText>
        </CustomBox>
    )
}

export default Header


/*
        // color de fondo
        backgroundColor: 'red',
        opacity: 1,



                <BoxSearch
                    component='section'
                >
                    <Search />
                </BoxSearch>

*/