import React, {useState, useEffect} from 'react'
import {
    AppBar,
    Toolbar,
    Box,
    Button,
    List,
    ListItem,
    Typography, 
    Tooltip,
    styled,
    ListItemButton,
    ListItemText,
} from '@mui/material';

import { store } from '../store';

import AdminPanelSettingsOutlinedIcon from '@mui/icons-material/AdminPanelSettingsOutlined';

// menu
import DrawerItem from './DrawerItem';
// rotas
import { Link } from 'react-router-dom';

import logo from '../assets/images/buscatrasterologo.webp';

// personalizacao
const StyledToolbar = styled(Toolbar) ({
    display: 'flex',
    justifyContent: 'space-between',
});

const ListMenu = styled(List)(({ theme }) => ({
    display: 'none',
    [theme.breakpoints.up("sm")] : {
        display: "flex",
    },
}));

//rotas
const itemList = [
    {
      text: "Home",
      to: "/" 
    },
    {
        text: "Propiedades",
        to: "/property"
    },
    {
      text: "Nosotros",
      to: "/about"
    },
    {
        text: "Faqs",
        to: "/faq"
      },
      {
        text: "Contáctenos",
        to: "/contact"
    }
];


const Navbar = () => {
    const [parametros, setParametros] = useState(store.getState().parameters);
    const [adminURL, setAdminURL] = useState("");
    const [registerURL, setRegisterURL] = useState("");

    useEffect(() => {

//        setAdminURL(parametros.httpConfig.adminURL);
/*
        console.log("****************************************");
        console.log(parametros);
        console.log(parametros.parameters);
        console.log(parametros.parameters.httpConfig);
        console.log(parametros.parameters.httpConfig.adminURL);
        console.log("****************************************");
*/        
        setAdminURL(parametros.parameters.httpConfig.adminURL);
        setRegisterURL(parametros.parameters.httpConfig.registerURL);

    }, [parametros])

    return (
        <AppBar 
        component="nav" 
        position="sticky"
        sx={{ 
            backgroundColor: 'white', 
        }}
        elevation={0}
        >
            <StyledToolbar>
                <Typography
                variant="h6"
                component="h2"

            >
                <Box>
                    <img src={logo} alt="Logo" width="150px" height="150px"/>
                </Box>
                </Typography>
                <Box sx={{display: { xs: 'block', sm: 'none' } }}>
                    <DrawerItem /> 
                </Box>
                <ListMenu>
                    {itemList.map( ( item ) => {
                        const { text } = item;
                        return(
                            <ListItem key={text}>
                                <ListItemButton component={Link} to={item.to}
                                sx={{
                                    color: '#primary',
                                    "&:hover": {
                                        backgroundColor: 'transparent',
                                        color: '#1e2a5a',
                                    }
                                }}
                                >
                                    <ListItemText primary={text} />
                                </ListItemButton>
                            </ListItem>
                        )
                    })}
                </ListMenu>
                <Box>
                    <Tooltip 
                        title="Registrate en Nuestro Portal"
                    >
                        <a 
                            href={ registerURL }
                            rel='noreferrer'
                            target='_blank'
                        >
                            <Button variant="contained">PUBLICA TU<br/>TRASTERO GRATIS</Button>
                        </a>
                    </Tooltip>
                </Box>
                <Box>
                    <Tooltip 
                        title="Acceso al Panel Administrativo"
                    >
                        <a 
                            href={ adminURL }
                            rel='noreferrer'
                            target='_blank'
                        >
                            <AdminPanelSettingsOutlinedIcon  sx={{ fontSize: '2.5rem' }} />
                        </a>
                    </Tooltip>
                </Box>
            </StyledToolbar>
        </AppBar>
    )
}

export default Navbar;
