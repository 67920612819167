import * as React from 'react';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';

export default function SplitButton({ options = [{id: 0, name: '(Vacio)'}, {id: 1, name: 'Uno'}], onChange }) {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const [listOptions, setListOptions] = React.useState([]);

  /*
  const [listOptions, setListOptions] = React.useState([
    {id: 0, name: 'Create a merge commit'}, 
    {id: 1, name: 'Squash and merge'}, 
    {id: 2, name: 'Rebase and merge'}
  ]);
*/  
  const handleClick = () => {
    console.info(`handleClick ${listOptions[selectedIndex].id} - ${listOptions[selectedIndex].name}`);
  };
  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    setOpen(false);

//    console.info(`handleMenuItemClick ${listOptions[index].id} - ${listOptions[index].name}`);
//    console.info(`handleMenuItemClick ${listOptions[selectedIndex].id} - ${listOptions[selectedIndex].name}`);
    onChange(event, listOptions[index].id);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event, index) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  React.useEffect(() => {
    /*
    console.log("***********************************************");
    console.log("Horario: ", new Date());
    console.log("options ==>> ", options);
    console.log("***********************************************");
    */
    setListOptions(options);
  }, [options])

  return (
    <React.Fragment>
      <ButtonGroup
        variant="contained"
        color="terciary"
        ref={anchorRef}
        aria-label="Button group with a nested menu"
      >
        <Button onClick={handleClick}>{ (listOptions.length> 0? listOptions[selectedIndex].name : '(Vacio)')}</Button>
        <Button
          size="small"
          aria-controls={open ? 'split-button-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-label="select merge strategy"
          aria-haspopup="menu"
          onClick={handleToggle}
        >
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <Popper
        sx={{
          zIndex: 1,
        }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList 
                  id="split-button-menu" 
                  autoFocusItem
                >
                  {listOptions.map((option, index) => (
                    <MenuItem
                      key={option.id}
                      selected={index === selectedIndex}
                      onClick={(event) => handleMenuItemClick(event, index)}
                    >
                      { option.name }
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </React.Fragment>
  );
}
