import React, {useState, useEffect} from 'react';
import { useDispatch } from 'react-redux';

// material-ui
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import theme from './themes';
import * as actionTypes from '../src/store/actions';

//routes
import {BrowserRouter, Routes, Route} from 'react-router-dom';
//pages
import Home from './pages/Home';
import About from './pages/About';
import Contact from './pages/Contact';
import Property from './pages/Property';
import PropertyDetail from './pages/PropertyDetail';
import PropertyEmail from './pages/PropertyEmail';
import Faq from './pages/Faq';

//components
import Navbar from './components/Navbar';
import Footer from './components/Footer/Footer';

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      const response = await fetch(
        "/config/parameters.json"
      );
      const parsed = await response.json();
      dispatch({ type: actionTypes.SET_PARAMETERS, parameters: parsed });
    })();

  }, []);
  return (
    <>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme()}>
          <CssBaseline />
          <BrowserRouter>
            <Navbar />
            <Routes>
              <Route path='/' element={<Home />} />
              <Route path='/about' element={<About />} />
              <Route path='/contact' element={<Contact />} />
              <Route path='/faq' element={<Faq />} />
              <Route path='/property' element={<Property />} />
              <Route path='/property/detail' element={<PropertyDetail />} />
              <Route path='/property/email' element={<PropertyEmail />} />
            </Routes>
          </BrowserRouter>
          <Footer />
        </ThemeProvider>
      </StyledEngineProvider>
    </>
  );
}

export default App;