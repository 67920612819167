import React from 'react'
import { 
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography 
} from '@mui/material'

import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

/* LLAMADAS DE SERVICIOS API */
import SiteService from '../services/SiteService';

const Cuestionary = ({ id, question, answer }) => {

  const handleAccordionOnChange = async () => {
    try {
      await SiteService.OPERATIONLOG_Register({
          type: "faq",
          action: "access",
          associated_id: id
      });
    } catch (err) {
        const errorMessage = "Error: " + err.message;
        console.log(errorMessage);
    }

  };

  return (
    <Accordion
      onChange={ (e, expanded) => {
        if (expanded) {
          handleAccordionOnChange();
        }
      }}
    >
      <AccordionSummary
        expandIcon={<ArrowDropDownIcon />}
        aria-controls="panel1-content"
        id="panel1-header"
      >
        <Typography variant="h3">{ question }</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography>
          { answer }
        </Typography>
      </AccordionDetails>
    </Accordion>
  )
}

export default Cuestionary