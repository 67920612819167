import { object, string, number, date, boolean } from "yup";

export const formikLabel = {
  country_id: "País",
  state_id: "Estado/Provincia",
  city_id: "Ciudad",
  material_id: "Material",
  commercialoperation_id: "Operación Comercial",
  m3_min: "Mínima",
  m3_max: "Máxima",
  price_min: "Mínimo",
  price_max: "Máximo",
  years_min: "Mínimo",
  years_max: "Máximo"
};

export const formikValidationSchema = object({
  country_id: number().min(0, 'Debe ser mayor o igual a cero'), //.required('Se requiere el País'),
  state_id: number().min(0, 'Debe ser mayor o igual a cero'), //.required('Se requiere el Estado/Provincia'),
  city_id: number().min(0, 'Debe ser mayor o igual a cero'), //.required('Se requiere la Ciudad'),
  material_id: number().min(0, 'Debe ser mayor o igual a cero'), //.required('Se requiere el Material'),
  commercialoperation_id: number().min(0, 'Debe ser mayor o igual a cero'), //.required('Se requiere la Operación Comercial'),
  m3_min: number().min(0, 'Debe ser mayor o igual a cero'), //.required('Se requiere el Material'),
  m3_max: number().min(0, 'Debe ser mayor o igual a cero'), //.required('Se requiere el Material'),
  price_min: number().min(0, 'Debe ser mayor o igual a cero'), //.required('Se requiere el Material'),
  price_max: number().min(0, 'Debe ser mayor o igual a cero'), //.required('Se requiere el Material'),
  years_min: number().min(0, 'Debe ser mayor o igual a cero'), //.required('Se requiere el Material'),
  years_max: number().min(0, 'Debe ser mayor o igual a cero'), //.required('Se requiere el Material'),
});
