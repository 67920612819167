import React, { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';

import { useFormik } from 'formik';

import {
  Alert,
  Box,
  Button,
  Card,
  CardHeader,
  CardContent,
  Grid,
  MenuItem,
  Snackbar,
  TextField,
  Tooltip,
} from '@mui/material';

import { gridSpacing } from '../../../config';
import { useTheme } from '@mui/material/styles';

import SplitButton from '../../SplitButton';

import { useDispatch } from 'react-redux';
import * as actionTypes from '../../../store/actions';

/* LLAMARDAS DE SERVICIOS API */
import SiteService from '../../../services/SiteService';

/* DATOS PARA EL FORMULARIO */
import { formikValidationSchema } from './initialFormik';


export default function Search() {
  const theme = useTheme();
  const [isLoading, setIsLoading] = useState(true);
  const [isShowResults, setIsShowResults] = useState(false);

  const dispatch = useDispatch();

  const [open, setOpen] = React.useState(false);
  const [alerta, setAlerta] = React.useState({
    tipo: 'error',
    mensaje: ''
  });

  /* VARIABLES GENERALES DEL FORMULARIO */

  const [countries, setCountries] = useState([{id: 0, name: "(Países)", currency: '', currency_symbol: ''}]);
  const [states, setStates] = useState([{id: 0, name: "(Estados/Provincias)"}]);
  const [cities, setCities] = useState([{id: 0, name: "(Ciudades)"}]);
  const [commercialOperations, setCommercialOperations] = useState([{id: 0, name: "(Todas)"}]);

  let formikInitialData= {
    country_id: 0,
    state_id: 0,
    city_id: 0,
    material_id: 0,
    commercialoperation_id: 0,
    m3_min: 0,
    m3_max: 0,
    price_min: 0,
    price_max: 0,
    years_min: 0,
    years_max: 0
  };


  /* FORMIK: DEFINICION */
  const {handleSubmit, handleChange, values, errors, touched, isSubmitting} = useFormik({
    initialValues: formikInitialData,
    validationSchema: formikValidationSchema,
    enableReinitialize: true,
    onSubmit: ( data, actions ) => { onSubmitData(data, actions) }
  });


  const onSubmitData = async (data) => {  //, actions

    dispatch({ type: actionTypes.SET_SEARCH, search: {
      country_id: data.country_id,
      state_id: data.state_id,
      city_id: data.city_id,
      material_id: data.material_id,
      commercialoperation_id: data.commercialoperation_id,
      m3_min: 0,
      m3_max: 0,
      price_min: 0,
      price_max: 0,
      years_min: 0,
      years_max: 0
    } });

    try {
      await SiteService.OPERATIONLOG_Register({
          type: "critery",
          action: "search",
          country_id: data.country_id,
          state_id: data.state_id,
          city_id: data.city_id,
          material_id: 0,
          total_mts3_min: 0,
          total_mts3_max: 0,
          construction_min: 0,
          construction_max: 0,
          commercialoperation_id: data.commercialoperation_id,
          price_min: 0,
          price_max: 0
        });
    } catch (err) {
        const errorMessage = "Error: " + err.message;
        console.log(errorMessage);
    }

    setIsShowResults(true);
  }

  const handleClose = () => {
    setOpen(false);
  };

  const handleSplitButtonChange = (event, commercialoperation_id) => {
    values.commercialoperation_id = parseInt(commercialoperation_id);

    console.log("commercialOperations_id => ", values.commercialoperation_id);
  };

  const COUNTRY_List = async () => {
    try {
      const response = await SiteService.COUNTRY_List("(Países)");
      setCountries(response.datos.data);

    } catch (err) {
      const errorMessage = "Error: " + err.message;
      setAlerta({
        tipo: "error",
        mensaje: "Error: " + err.message
      })
      setAlerta({
        tipo: "error",
        mensaje: "Error: " + err.message
      })
      console.log(errorMessage);
    }

  }

  const STATE_listByCountry = async (country_id) => {
    try {
      const response = await SiteService.STATE_ListByCountry(country_id);
      setStates(response.datos.data);

    } catch (err) {
      const errorMessage = "Error: " + err.message;
      setAlerta({
        tipo: "error",
        mensaje: "Error: " + err.message
      })
      console.log(errorMessage);
    }

  }

  const CITY_listByCountryAndState = async (country_id, state_id) => {
    try {
      const response = await SiteService.CITY_ListByCountryAndState(country_id, state_id);
      setCities(response.datos.data);

    } catch (err) {
      const errorMessage = "Error: " + err.message;
      setAlerta({
        tipo: "error",
        mensaje: "Error: " + err.message
      })
      console.log(errorMessage);
    }
  }


  const COMMERCIALOPERATION_List = async () => {
    try {
      const response = await SiteService.COMMERCIALOPERATION_List("(Todas las Operaciones Comerciales)");
      setCommercialOperations(response.datos.data);

    } catch (err) {
      const errorMessage = "Error: " + err.message;
      setAlerta({
        tipo: "error",
        mensaje: "Error: " + err.message
      })
      console.log(errorMessage);
    }

  }


  /* SE EJECUTA CUANDO  DETECTA EL CAMBIO DE PAIS = values.country_id */
  useEffect(() => {
    if (!isLoading) {
      if (values.country_id > 0) {
        const item = countries.find(item => item.id === values.country_id);
        values.currency = item.currency;

        STATE_listByCountry(values.country_id);

      } else {
        values.state_id = 0;
        values.city_id = 0;
        values.currency = '';
    
        setStates([
          {id: 0, name: "(Estados/Provincias)"},
        ]);
    
        setCities([
          {id: 0, name: "(Ciudades)"},
        ]);
    
      }
    }
  }, [values.country_id]);

  /* SE EJECUTA CUANDO DETECTA EL CAMBIO DE ESTADO/PROVINCIA = values.state_id */
  useEffect(() => {
    if (!isLoading) {
      if (values.state_id > 0) {

        CITY_listByCountryAndState(values.country_id, values.state_id);

      } else {
        values.city_id = 0;
        setCities([
          {id: 0, name: "(Todas)"},
        ]);
      }
    }
  }, [values.state_id]);

  /* SE EJECTURA UNA VEZ AL INGRESAR */
  useEffect(() => {

    setCountries([
      {id: 0, name: "(Países)", currency: '', currency_symbol: ''},
    ]);

    setStates([
      {id: 0, name: "(Estados/Provincias)"},
    ]);

    setCities([
      {id: 0, name: "(Ciudades)"},
    ]);

    setCommercialOperations([
      {id: 0, name: "(Operaciones Comerciales)"},
    ]);

    COUNTRY_List();
    COMMERCIALOPERATION_List();

    setIsLoading(false);
/*
    console.log("***********************************************************");
    console.log("Horario              => ", new Date());
    console.log("commercialOperations => ", commercialOperations);
    console.log("***********************************************************");
*/
  }, []);

  if (isShowResults) {
    return  <Navigate to="/property" />
  }
  
  return (
    <>
      <Grid container spacing={gridSpacing}>
        <Grid item xs={12} sx={{ mt: theme.spacing(4), mb: theme.spacing(1) }}>
          <form onSubmit={ handleSubmit}>
            <Card 
              sx={{ width: 'auto', 
                height: '100%', 
                // color de fondo
                backgroundColor: 'rgba(109,66,155,0.3)',
              }}
              variant='outlined'
            >
            <CardHeader />
              <CardContent sx={{ width: 'auto', height: '100%' }}>
                <Grid container spacing={gridSpacing}>
                  <Grid item xs={12}
                      sx={{ 
                        ml: theme.spacing(10), 
                        mr: theme.spacing(10),
                      }}
                  >
                    <SplitButton 
                      options={ commercialOperations }
                      onChange={ handleSplitButtonChange }
                    ></SplitButton>

                  </Grid>
                  <Grid item xs={12}
                      sx={{ 
                        ml: theme.spacing(20), 
                        mr: theme.spacing(20),
                      }}
                  >
                    <TextField name="country_id" 
                      select={true}
                      variant="outlined" 
                      color='terciary'
                      focused
                      sx={{ 
                        mt: theme.spacing(1), 
                        mb: theme.spacing(1),
                        backgroundColor:"white",

                        "& .MuiOutlinedInput-root": {
                          color: "terciary",
                          // Class for the border around the input field
                          "& .MuiOutlinedInput-notchedOutline": {
                            borderColor: "terciary",
                          },
                        },
                        // Class for the label of the input field
                        "& .MuiInputLabel-outlined": {
                          color: "terciary",
                        },                        
                      
                      
                      }}
                      fullWidth
                      onChange={handleChange}
                      value={values.country_id}
                      error={touched.country_id && errors.country_id !== undefined}
                      helperText={errors.country_id}
                    >
                      {
                        countries.map((item) => (
                        <MenuItem key={item.id} value={item.id}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </TextField>
                    <TextField name="state_id" 
                      select={true}
                      variant="outlined" 
                      color='terciary'
                      focused
                      sx={{ 
                        mt: theme.spacing(1), 
                        mb: theme.spacing(1),
                      
                        backgroundColor:"white",

                        "& .MuiOutlinedInput-root": {
                          color: "terciary",
                          // Class for the border around the input field
                          "& .MuiOutlinedInput-notchedOutline": {
                            borderColor: "terciary",
                          },
                        },
                        // Class for the label of the input field
                        "& .MuiInputLabel-outlined": {
                          color: "terciary",
                        },                        
                      
                      }}
                      fullWidth
                      onChange={handleChange}
                      value={values.state_id}
                      error={touched.state_id && errors.state_id !== undefined}
                      helperText={errors.state_id}
                    >
                      {
                        states.map((item) => (
                        <MenuItem key={item.id} value={item.id}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </TextField>
                    <TextField name="city_id" 
                      select={true}
                      variant="outlined" 
                      color='terciary'
                      focused
                      sx={{ 
                        mt: theme.spacing(1), 
                        mb: theme.spacing(1),
                      
                        backgroundColor:"white",

                        "& .MuiOutlinedInput-root": {
                          color: "terciary",
                          // Class for the border around the input field
                          "& .MuiOutlinedInput-notchedOutline": {
                            borderColor: "terciary",
                          },
                        },
                        // Class for the label of the input field
                        "& .MuiInputLabel-outlined": {
                          color: "terciary",
                        },                        
                      
                      }}
                      fullWidth
                      onChange={handleChange}
                      value={values.city_id}
                      error={touched.city_id && errors.city_id !== undefined}
                      helperText={errors.city_id}
                    >
                      {
                        cities.map((item) => (
                        <MenuItem key={item.id} value={item.id}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={12}>
                    <Box mt={2} display="flex" justifyContent="flex-end">
                      <Tooltip title="Buscar">
                          <Button aria-label="buscar" disabled={isSubmitting} type="submit" variant="contained" color="terciary" size="large"><b>BUSCAR</b></Button>
                      </Tooltip>
                    </Box>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </form>

        </Grid>
      </Grid>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}
          anchorOrigin={{
          vertical: "bottom",
          horizontal: "right"
        }}>
        <Alert
          onClose={handleClose}
          severity={alerta.tipo}
          variant="filled"
          sx={{ width: '100%' }}
        >
          {alerta.mensaje}
        </Alert>
      </Snackbar>

    </>
  );
}