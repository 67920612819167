import React from 'react';

import GetFaq from '../components/GetFaq';
import Carousel from '../components/Carousel';

const Faq = () => {
  return (
    <>
      <GetFaq />
      <Carousel page={"Faq"}/>
    </>
  )
}

export default Faq