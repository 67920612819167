import React, { useState, useEffect } from 'react';

import {
  Box,
  Grid,
  Pagination,
  styled,
  Toolbar,
  Typography
} from '@mui/material';

import { useTheme } from '@mui/material/styles';

import logo from '../assets/images/cadamomento.webp';
import Carousel from './Carousel';

// personalizacao
const StyledToolbar = styled(Toolbar) ({
  display: 'flex',
  justifyContent: 'space-between',
  backgroundColor: 'white', 

});

export default function CarouselHome() {
  const theme = useTheme();

  return (
    <>
      <StyledToolbar>
          <Typography
          variant="h6"
          component="h2"

      >
          <Box>
              <img src={logo} alt="Logo" width="100%" height="100%"/>
          </Box>
          </Typography>
          <Box sx={{display: { xs: 'block', sm: 'none' } }}>
          </Box>
      </StyledToolbar>
      <Box
        backgroundColor= 'white'
      >
        <Carousel page={"Home"} />
      </Box>
    </>
  );
}
