// third party
import { combineReducers } from 'redux';

// project import
import currentSessionReducer from './currentSessionReducer';
import parametersReducer from './parametersReducer';

// ==============================|| REDUCER ||============================== //

const reducer = combineReducers({
  currentSession: currentSessionReducer,
  parameters: parametersReducer
});

export default reducer;
